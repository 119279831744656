import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import BuzzinPage from './BuzzinPage';
import PrivacyPage from './PrivacyPage';
import CivitasPage from './CivitasPage';
import CivitasPrivacyPage from './CivitasPrivacyPage';
import TypesetterPage from './TypesetterPage';
import TypesetterPrivacyPage from './TypesetterPrivacyPage';
import LongevityPage from './LongevityPage';
import LongevityPrivacyPage from './LongevityPrivacyPage';
import ZeroProofPage from './ZeroProofPage';
import ZeroProofPrivacyPage from './ZeroProofPrivacyPage';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/buzzin" element={<BuzzinPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/typesetter" element={<TypesetterPage />} />
        <Route path="/civitas" element={<CivitasPage />} />
        <Route path="/civitas-privacy" element={<CivitasPrivacyPage />} />
        <Route path="/typesetter-privacy" element={<TypesetterPrivacyPage />} />
        <Route path="/longevitychecklist" element={<LongevityPage />} />
        <Route path="/longevitychecklist/privacy" element={<LongevityPrivacyPage />} />
        <Route path="/longevitychecklist-privacy" element={<LongevityPrivacyPage />} />
        <Route path="/zeroproof" element={<ZeroProofPage />} />
        <Route path="/zeroproof-privacy" element={<ZeroProofPrivacyPage />} />
      </Routes>
    </Router>
  );
}
export default App;
