import React, { useState } from 'react';

import './ZeroProofPage.css'

import longevityChecklistLogo from './images/ZeroProofLogo.png';
import heroImage from './images/ZeroProofHero.png';
import qrImage from './images/LongevityQrCode.png';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="zero-proof-modal-overlay" onClick={onClose}>
      <div className="zero-proof-modal-content" onClick={e => e.stopPropagation()}>
        <button className="zero-proof-modal-close" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

const ZeroProofPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  const handleBurgerClick = (e) => {
    setIsMenuOpen(e.target.checked);
  };

  return (
    <div className="zero-proof-container">
      <div className="zero-proof-glass-header">
        <div className="zero-proof-header-content">
          <div className="zero-proof-logo-container">
            <img
              src={longevityChecklistLogo}
              alt="Zero Proof Logo"
              className="zero-proof-logo-image"
              onClick={() => window.scrollTo(0, 0)}
            />
          </div>

          <div className="zero-proof-header-controls">
            {/* <button
              className="zero-proof-qr-button"
              onClick={() => setIsQRModalOpen(true)}
            >
              <span>Download</span>
            </button> */}

            <label className="zero-proof-burger" htmlFor="zero-proof-burger">
              <input
                type="checkbox"
                id="zero-proof-burger"
                checked={isMenuOpen}
                onChange={handleBurgerClick}
              />
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>

          {isMenuOpen && (
            <div className="zero-proof-dropdown-menu">
              <div
                className="zero-proof-menu-item"
                onClick={() => document.querySelector('.zero-proof-features-section').scrollIntoView({ behavior: 'smooth' })}
              >
                Benefits
              </div>
              <div
                className="zero-proof-menu-item"
                onClick={() => window.open('https://apps.apple.com/us/app/zero-proof/id6737241919?itscg=30200&itsct=apps_box_badge&mttnsubad=6737241919', '_blank')}
              >
                Download
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={isQRModalOpen} onClose={() => setIsQRModalOpen(false)}>
        <div className="zero-proof-qr-container">
          <h2 className="zero-proof-qr-title">Scan to Download</h2>
          <img
            src={qrImage}
            alt="QR Code"
            className="zero-proof-qr-code"
          />
          <p className="zero-proof-qr-description">Scan this QR code with your mobile device to download the Zero Proof app</p>
        </div>
      </Modal>


      <div className="zero-proof-hero-container">
        <div className="zero-proof-hero-wrapper">
          <div className="zero-proof-hero-text-content">
            <h1 className="zero-proof-hero-title">Stay motivated with Zero Proof's Streak Tracker</h1>
            <p className="zero-proof-hero-description">
              Watch your alcohol-free days stack up as you build momentum toward a healthier, clearer life.
              Each day sober is a win—improving your sleep, saving money, and sharpening your focus.
            </p>
            {/* <a
              href="https://apps.apple.com/us/app/zero-proof/id6737241919?itscg=30200&itsct=apps_box_badge&mttnsubad=6737241919"
              style={styles.appStoreButtonContainer}
            >
              <img
                src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1730073600"
                alt="Download on the App Store"
                style={styles.appStoreButtonOfficial}
              />
            </a> */}
          </div>
          <div className="zero-proof-hero-image-container">
            <img
              src={heroImage}
              alt="Zero Proof App"
              className="zero-proof-hero-image"
            />
          </div>
        </div>

        <div className="zero-proof-features-section">
          <h3 className="zero-proof-features-title">Life-Changing Benefits Await</h3>
          <div className="zero-proof-features-cards">
            <div className="zero-proof-feature-card">
              <h4>Sleep Better</h4>
              <p>Wake up refreshed, not groggy. Without alcohol disrupting your sleep cycles, you'll experience deeper, more restful sleep and increased energy throughout the day.</p>
            </div>
            <div className="zero-proof-feature-card">
              <h4>Mental Edge</h4>
              <p>Gain razor-sharp focus, boundless energy, and strategic thinking. Without alcohol fogging your mind, you'll experience improved concentration and mental clarity.</p>
            </div>
            <div className="zero-proof-feature-card">
              <h4>No More Hangovers</h4>
              <p>Kiss pounding headaches and wasted mornings goodbye. Reclaim your weekends and start making the most of every day.</p>
            </div>
            <div className="zero-proof-feature-card">
              <h4>Save Money</h4>
              <p>Stop pouring cash into drinks and nights out—build wealth instead. Watch your savings grow as you cut out unnecessary alcohol expenses.</p>
            </div>
            <div className="zero-proof-feature-card">
              <h4>Glow Up</h4>
              <p>Watch your skin clear up and your confidence soar. Alcohol dehydrates your body and affects your appearance—without it, you'll look and feel better.</p>
            </div>
            <div className="zero-proof-feature-card">
              <h4>Stronger Relationships</h4>
              <p>Be present and intentional with the ones you love. Build deeper connections and more meaningful experiences without alcohol interfering.</p>
            </div>
          </div>
        </div>

        {/* <div className="zero-proof-hero-text-content" style={{textAlign: 'center', marginTop: '40px', padding: '0 20px'}}>
          <p className="zero-proof-hero-description" style={{fontSize: '1.3rem'}}>
            Whether you're cutting back or quitting for good, Zero Proof empowers you to take control.
            Download now and start building a life of clarity, health, and purpose—one sober day at a time.
          </p>
          <a
            href="https://apps.apple.com/us/app/zero-proof/id6737241919?itscg=30200&itsct=apps_box_badge&mttnsubad=6737241919"
            style={{...styles.appStoreButtonContainer, display: 'inline-block', marginTop: '20px'}}
          >
            <img
              src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1730073600"
              alt="Download on the App Store"
              style={styles.appStoreButtonOfficial}
            />
          </a>
        </div> */}
      </div>

      <footer className="zero-proof-footer">
        <div className="zero-proof-footer-container">
        </div>
        <p className="zero-proof-footer-credits">Build a better tomorrow, one alcohol-free day at a time.</p>
      </footer>
    </div>
  );
};

// const styles = {
//   appStoreButtonContainer: {
//     marginBottom: '20px',
//   },
//   appStoreButtonOfficial: {
//     width: '170px',
//     height: 'auto',
//   }
// };

export default ZeroProofPage;