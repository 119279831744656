import React from 'react';
import { Link } from 'react-router-dom';

import civitasLogo from './images/civitasLogo.png'
import longevityChecklistLogo from './images/LongevityLogo.png'
import typesetterLogo from './images/typesetter.png'
import zeroProofLogo from './images/ZeroProofLogo.png'
//import buzzinLogo from './images/buzzinLogo.png'
import ParticlesBackground from './ParticlesBackground';

// import { Menu } from '@mui/icons-material';
// import externalStyles from './styles/HomePageStyles.css';

function HomePage() {
    //const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div style={styles.container}>
            <ParticlesBackground />
            {/* <div style={styles.expandableButton}>
                {isExpanded && (
                    <div style={externalStyles.parent}>
                        <Link to="/longevity-checklist" style={externalStyles.child}>
                            Longevity Checklist
                        </Link>
                        <Link to="/typesetter" style={externalStyles.child}>
                            Typesetter
                        </Link>
                        <Link to="/buzzin" style={externalStyles.child}>
                            Buzzin
                        </Link>
                    </div>
                )}
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    style={styles.mainButton}
                >
                    <Menu />
                </button>
            </div> */}
            <div style={styles.blurredCard}>
                {/* <h1 style={styles.title}>Currently Available iOS Apps</h1> */}
                <div style={styles.buttonContainer}>
                    <div style={styles.logoContainer}>
                        <Link to="/civitas">
                            <img
                                src={civitasLogo}
                                alt="Longevity Checklist Logo"
                                style={styles.logoImage}
                            />
                        </Link>
                    </div>
                    <div style={styles.logoContainer}>
                        <Link to="/typesetter">
                            <img
                                src={typesetterLogo}
                                alt="Typesetter Logo"
                                style={styles.logoImage}
                            />
                        </Link>
                    </div>
                    <div style={styles.logoContainer}>
                        <Link to="/zeroproof">
                            <img
                                src={zeroProofLogo}
                                alt="Zero Proof Logo"
                                style={styles.logoImage}
                            />
                        </Link>
                    </div>
                    <div style={styles.logoContainer}>
                        <Link to="/longevitychecklist">
                            <img
                                src={longevityChecklistLogo}
                                alt="Longevity Checklist Logo"
                                style={styles.logoImage}
                            />
                        </Link>
                    </div>

                    {/* <div style={styles.logoContainer}>
                        <Link to="/buzzin">
                            <img
                            src={buzzinLogo}
                            alt="Buzzin Logo"
                            style={styles.logoImage}
                            />
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
    },
    expandableButton: {
        position: 'absolute',
        justifyContent: 'right',
        alignItems: 'right',
        display: 'flex',
        top: '20px',
        right: '20px',
        background: 'rgba(100, 100, 100, 0.5)',
        backdropFilter: 'blur(5px)',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    mainButton: {
        background: 'rgba(255, 255, 255, 0)',
        border: 'none',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
    },

    blurredCard: {
        display: 'flex',
        background: 'rgba(255, 255, 255, 0.01)',
        backdropFilter: 'blur(5px)',
        borderRadius: '20px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80vw',
        maxWidth: '500px',
        height: '40vh',
    },
    title: {
        fontSize: '1.5em',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        width: '70%',
    },
    logoContainer: {
        padding: '5px',

    },
    logoImage: {
        width: '100px',
        height: '100px',
        maxWidth: '100%',
        objectFit: 'contain',
        borderRadius: '20px',
        overflow: 'hidden',
    }
}

export default HomePage;